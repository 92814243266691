import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Header = props => (
  <div id="center-info" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-paper-plane" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>The Travel Guide</h1>
        <p>Providing you with a seamless travel experience.</p>
      </div>
    </div>
    <nav>
      <ul>
        {/* <li>
          <Link to="/about">
            <button type="button">About</button>
          </Link>
        </li>
        <li>
          <Link to="/services">
            <button type="button">Services</button>
          </Link>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact');
            }}
            type="button"
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </div>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func.isRequired,
  timeout: PropTypes.bool.isRequired,
};

export default Header;
